/* Start Work Steps */
.work-steps {
  padding-top: var(--main-padding-top);
  padding-bottom: var(--main-padding-bottom);
  background-color: var(--section-background);
  position: relative;
}

.work-steps .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .work-steps .container {
    flex-direction: column;
  }
}

.work-steps .image {
  width: 415px;
  margin-right: 100px;
}

@media (max-width: 991px) {
  .work-steps .image {
    width: 200px;
    margin: 0 0 50px;
  }
}

.work-steps .info .box {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: 2px solid white;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .work-steps .info .box {
    flex-direction: column;
    text-align: center;
  }
}

.work-steps .info .box::before {
  content: "";
  left: 50%;
  top: 50%;
  position: absolute;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  background-color: #ededed;
  z-index: -1;
  transition: var(--main-transition);
}

.work-steps .info .box:hover::before {
  width: 100%;
  height: 100%;
}

.work-steps .info .box img {
  width: 64px;
  margin-right: 30px;
}

@media (max-width: 767px) {
  .work-steps .info .box img {
    margin: 0 0 30px;
  }
}

.work-steps .info .box h3 {
  margin: 0;
  font-size: 22px;
}

.work-steps .info .box p {
  color: #777;
  line-height: 1.7;
  margin: 10px 0 0;
  font-size: 18px;
}

/* End Work Steps */
