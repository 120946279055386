/* Start Footer */
.footer {
  background-color: var(--main-color);
  color: white;
  padding: 30px 10px;
  text-align: center;
  font-size: 18px;
}
.footer span {
  font-weight: bold;
  color: var(--main-color-alt);
}
/* End Footer */
