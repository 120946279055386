/* Start Landing */
.landing {
  position: relative;
}

.landing::before {
  content: "";
  position: absolute;
  left: 0;
  top: -40px;
  width: 100%;
  height: 100%;
  background-color: var(--section-background);
  z-index: -1;
  transform: skewY(-6deg);
  transform-origin: top left;
}

.landing .container {
  min-height: calc(100vh - 72px);
  display: flex;
  align-items: center;
  padding-bottom: 120px;
}

.landing .text {
  flex: 1;
}

@media (max-width: 991px) {
  .landing .text {
    text-align: center;
  }
}

.landing .text h1 {
  font-size: 36px;
  margin: 0;
  letter-spacing: -2px;
}

@media (max-width: 767px) {
  .landing .text h1 {
    font-size: 28px;
  }
}

.landing .text p {
  line-height: 1.7;
  margin: 5px 0 0;
  color: #666;
  max-width: 500px;
}

@media (max-width: 991px) {
  .landing .text p {
    margin: 10px auto;
  }
}

@media (max-width: 767px) {
  .landing .text p {
    font-size: 18px;
  }
}

.landing .image img {
  position: relative;
  animation: up-and-down 5s linear infinite;
  width: 500px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

@media (max-width: 991px) {
  .landing .image {
    display: none;
  }
}

.landing .go-down {
  color: var(--main-color);
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  transition: var(--main-transition);
}

.landing .go-down:hover {
  color: var(--main-color-alt);
}

.landing .go-down i {
  animation: bouncing 1.5s infinite;
}

/* End Landing */

@keyframes up-and-down {
  0%,
  100% {
    top: 0px;
  }

  50% {
    top: -50px;
  }
}

@keyframes bouncing {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40%,
  60% {
    transform: translateY(-15px);
  }
}
