* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --main-color: #1e2d40;
  --main-color-alt: #0be1af;
  --main-transition: 0.3s;
  --main-padding-top: 100px;
  --main-padding-bottom: 100px;
  --section-padding: 60px;
  --section-background: #f6f6f6;
  --main-duration: 0.5s;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

/* Small */
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

/* Medium */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

/* Large */
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* Start Components */
.special-heading {
  color: #dfdfdf;
  font-size: 100px;
  text-align: center;
  font-weight: 800;
  letter-spacing: -3px;
  margin: 0;
}
.special-heading + p {
  margin: -30px 0 0;
  font-size: 20px;
  text-align: center;
  color: #797979;
}
@media (max-width: 767px) {
  .special-heading {
    font-size: 60px;
  }
  .special-heading + p {
    margin-top: -20px;
  }
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.7;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  right: 0;
  width: 113px;
  color: #0be1af;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@media (max-width: 767px) {
  .scrollTop {
    position: fixed;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.7;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    right: 5px;
    width: 25px;
    color: #0be1af;
  }
}
