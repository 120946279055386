/* Start features  */
.features {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  background-color: var(--section-background);
}

.features .features-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  margin-top: 100px;
}

.features .features-content .srv {
  display: flex;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .features .features-content .srv {
    flex-direction: column;
    text-align: center;
  }
}

.features .features-content .srv i {
  color: var(--main-color);
  flex-basis: 60px;
}

.features .features-content .srv .text {
  flex: 1;
}

.features .features-content .srv .text h3 {
  margin: 0 0 20px;
}

.features .features-content .srv .text p {
  color: #444;
  font-weight: 300;
  line-height: 1.6;
}

.features .features-content .image {
  text-align: center;
  position: relative;
}

.features .features-content .image::before {
  content: "";
  background-color: var(--main-color);
  width: 100px;
  /*
  TODO:
   height: calc(100% + 100px);
  */
  height: 480px;
  top: -50px;
  position: absolute;
  right: 0;
  z-index: -1;
}

.features .features-content .image img {
  /* width: 260px;
  TODO:
  height: 380px; */
}

@media (max-width: 1199px) {
  .image-column {
    display: none;
  }
}

/* Start features  */

/* Start features */
.features {
  padding-top: var(--main-padding-top);
  padding-bottom: var(--main-padding-bottom);
  position: relative;
  background-color: var(--section-background);
}

.features .container {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;
}

@media (max-width: 991px) {
  .features .container {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    gap: 40px;
    margin-top: 40px;
  }
}

.features .box {
  background-color: white;
  box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%), 0 2px 4px 0 rgb(0 0 0 / 12%);
  counter-increment: features;
  transition: var(--main-transition);
  position: relative;
  padding: 10px 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.features .box::before {
  content: "";
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  top: -3px;
  background-color: var(--main-color);
  position: absolute;
  width: 0;
  transition: var(--main-transition);
}

.features .box:hover {
  transform: translateY(-10px);
}

.features .box:hover::before {
  width: 100%;
}

.features .box > i {
  margin: 30px auto 20px;
  display: block;
  text-align: center;
  color: var(--main-color);
}

.features h3 {
  text-align: center;
  margin: 20px 0;
}

.features .box > h3 {
  text-align: center;
  margin: 20px 0 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: var(--main-color);
}

.features .box .info {
  padding: 15px;
  position: relative;
  background-color: #f9f9f9;
  text-align: right;
}

.features .box .info::before {
  content: "0" counter(features);
  position: absolute;
  background-color: var(--main-color);
  color: white;
  left: 0;
  top: 0;
  height: 100%;
  width: 80px;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}

.features .box .info::after {
  content: "";
  position: absolute;
  background-color: #d5d5d5;
  top: 0;
  left: 80px;
  width: 50px;
  height: calc(100% + 0.4px);
  transform: skewX(-30deg);
}

.features .box .info a {
  color: var(--main-color);
}

/* End features */
