/* Start About */
.about {
  padding-top: var(--section-padding);
  padding-bottom: calc(var(--section-padding) + 60px);
}
.about .about-content {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .about .about-content {
    flex-direction: column;
    text-align: center;
  }
}
.about .about-content .image {
  position: relative;
  width: 250px;
  height: 375px;
}
@media (max-width: 991px) {
  .about .about-content .image {
    margin: 0 auto 60px;
    display: none;
  }
}
.about .about-content .image::before {
  content: "";
  position: absolute;
  background-color: #ebeced;
  width: 100px;
  height: calc(100% + 80px);
  top: -40px;
  left: -20px;
  z-index: -1;
}
.about .about-content .image::after {
  top: -40px;
  content: "";
  position: absolute;
  width: 120px;
  height: 300px;
  border-left: 80px solid var(--main-color);
  border-bottom: 80px solid var(--main-color);
  z-index: -1;
  right: -150px;
}
@media (max-width: 991px) {
  .about .about-content .image::before,
  .about .about-content .image::after {
    display: none;
  }
}
.about .about-content .image img {
  /* max-width: 100%; */
  width: 311px;
  padding-left: 50px;
}
.about .about-content .text {
  flex-basis: calc(100% - 500px);
}
.about .about-content .text p:first-of-type {
  font-weight: bold;
  line-height: 2;
  margin-bottom: 50px;
}
.about .about-content .text hr {
  width: 50%;
  display: inline-block;
  border-color: var(--main-color);
}
.about .about-content .text p:last-of-type {
  line-height: 2;
  color: #777;
}
/* End About */
