/* Start Contact */
.contact {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
}
.contact .info {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
  text-align: center;
}
.contact .info .label {
  font-size: 25px;
  font-weight: 800;
  color: var(--main-color-alt);
  letter-spacing: -2px;
  margin-bottom: 15px;
}
.contact .info .link {
  display: block;
  font-size: 25px;
  font-weight: 800;
  color: var(--main-color);
  text-decoration: none;
}
.contact .info .social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
}
.contact .info .social i {
  margin-left: 10px;
  color: var(--main-color-alt);
}
@media (max-width: 767px) {
  .contact .info .label,
  .contact .info .link {
    font-size: 25px;
  }
}
.instagram {
  color: #bd32a6 !important;
}

.facebook {
  color: #3b5998 !important;
}

.twitter {
  color: #00acee !important;
}
/* End Contact */
