.service {
  padding-top: var(--main-padding-top);
  padding-bottom: var(--main-padding-bottom);
  position: relative;
  background-color: white;
}
.service .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px;
}
.service .box {
  text-align: center;
  border: 1px solid #ccc;
}
.service .box .img-holder {
  position: relative;
  overflow: hidden;
}
.service .box .img-holder::before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 100%;
}
.service .box .img-holder::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-style: solid;
  border-width: 0px 0px 170px 500px;
  border-color: transparent transparent white transparent;
  transition: var(--main-transition);
}
.service .box .img-holder img {
  max-width: 100%;
}
.service .box:hover .img-holder::after {
  border-width: 170px 500px 170px 0;
}
.service .box h3 {
  position: relative;
  margin: auto;
  width: fit-content;
}
.service .box h3::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 15px;
  height: 5px;
  width: calc(100% - 30px);
}
.service .box p {
  line-height: 2;
  margin: 30px 0;
  padding: 25px;
  color: #777;
}
.service .box a {
  display: block;
  border: 3px solid transparent;
  width: fit-content;
  margin: 0 auto 30px;
  font-weight: bold;
  font-size: 22px;
  padding: 10px 30px;
  border-radius: 6px;
  transition: var(--main-transition);
}
.service .quality .img-holder::before {
  background-color: rgb(223 223 223 / 60%);
}
.service .quality h3::after {
  background-color: var(--main-color);
}

.service .box:hover a {
  background-position: left bottom;
  color: white;
}
